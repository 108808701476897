import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import OtherService from "../api/backend/service/OtherService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


const headerBgStyle = {
    background: '#1e1e1e url(/assets/img/bg-footer.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    // backgroundBlendMode: 'multiply',
};

// slouzi k stylovani textfildu
const StyledTextField = styled(TextField)({
    "& .MuiInputLabel-root": {
        right: 0,
    },
    "& .MuiInputLabel-shrink": {
        margin: "0 auto",
        position: "absolute",
        right: "0",
        left: "0",
        top: "-10px",
        color: '#f0c031'
    },
    // "& .MuiOutlinedInput-root.Mui-focused": {
    //     "& legend ": {
    //         display: "none"
    //     }
    // }
    "& .MuiFormHelperText-root.Mui-error": {
        // backgroundColor: "transparent !important",
        margin: 0,
        backgroundColor: "#000",
        paddingLeft: "14px",
        paddingRight: "14px",
    }
});

interface InquiryFormProps {
    vin?: string
    infotextColor: string
}
const InquiryForm = ({vin, infotextColor} :InquiryFormProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [captchaOk, setCaptchaOk] = useState(false);
    const [gdpr, setGdpr] = useState<boolean>(false);
    const [submitFormSuccess, setSubmitFormSucces] = useState(false);

    const SignupSchema = Yup.object().shape({
        name: Yup.string().required("Povinné"),
        email: Yup.string()
            .email('Naplatný email.')
            .required('Povinné'),
        phone: Yup.string().required("Telefon je potřeba vyplnit.").required("Povinné"),
        partNumber: Yup.string().notRequired()
    });



    const sendGtagEvent = (event: string, eventname: string, params: Record<string, any>) => {
        if (typeof window.gtag === 'function') {
            console.log("odesilam event");
            window.gtag(event, eventname, params);
        } else {
            console.warn('gtag is not defined. Event not sent:', event, params);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            partNumber: vin,
            message: ""
        },
        validationSchema: SignupSchema,
        onSubmit: (values) => {
            console.log("odesilam", values)
            formik.setSubmitting(true);
            OtherService.postInquiryForm(values)
                .then(() => {
                    setSubmitFormSucces(true);
                    formik.resetForm();
                    setGdpr(false);
                    // odeslu konverzi do Ads
                    sendGtagEvent('event',  'conversion', {
                        'send_to': 'AW-16768554847/-7JGCJS_uO4ZEN-u77s-',
                    });
                    // odeslu konverzi do Analytics
                    sendGtagEvent('event',  'send_inquiry_form', {
                        'category': 'Odeslani formuláře',
                        'label': 'Poptávka',
                        'value': 1
                    });
                })
                .catch((error) => {
                    console.error("Chyba odeslani form: ", error);
                })
                .finally(() => {
                    formik.setSubmitting(false);
                });
        },
    });


    const [animationStyle, setAnimationStyle] = useState({
        opacity: 0,
        transition: 'opacity 0.5s ease-in-out',
    });

    // animace zobrazeni uspesneho odeslani form
    useEffect(() => {
        setAnimationStyle({
            opacity: submitFormSuccess ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
        });

        if (submitFormSuccess) {
            const hideTimeout = setTimeout(() => {
                setAnimationStyle({
                    opacity: 0,
                    transition: 'opacity 0.5s ease-in-out',
                });
                setSubmitFormSucces(false);
                setCaptchaOk(false);
            }, 5000);

            return () => clearTimeout(hideTimeout);
        }
    }, [submitFormSuccess]);

    const onChange = (value: string | null) => {
        setCaptchaOk(true);
    }

    return (
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
            <StyledTextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="name"
                label={t('inquiry_form.name')}
                name="name"
                autoComplete="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <StyledTextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('inquiry_form.email')}
                name="email"
                autoComplete="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />
            <StyledTextField
                size="small"
                margin="normal"
                required
                fullWidth
                name="phone"
                label={t('inquiry_form.phone')}
                id="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
            />
            {!vin &&
                <StyledTextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="partNumber"
                    label={t('inquiry_form.part_number')}
                    id="partNumber"
                    value={formik.values.partNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.partNumber && Boolean(formik.errors.partNumber)}
                    helperText={formik.touched.partNumber && formik.errors.partNumber}
                />
            }
            <StyledTextField
                size="small"
                margin="normal"
                fullWidth
                name="message"
                label={t('inquiry_form.message')}
                id="message"
                multiline
                rows={6}
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={gdpr}
                        onChange={() => setGdpr(!gdpr)}
                        color="primary"
                        sx={{
                            '&.MuiCheckbox-root': {
                                color: theme.palette.primary.main, // Základní barva rámečku, když není zatržený
                                '&.Mui-checked': {
                                    color: theme.palette.primary.main, // Barva ikony při zatržení
                                },
                            }
                        }}
                    />
                }
                label={
                    <Typography variant="caption" color={infotextColor}>
                        {t('inquiry_form.gdpr.text1')}{' '}
                        <a
                            href={'/files/zpracovani-osobnich-udaju.pdf'}
                            target={"_blank"}
                            style={{
                                color: theme.palette.primary.main,
                                textDecoration: 'none',
                            }}
                        >
                            {t('inquiry_form.gdpr.text2')}
                        </a>
                    </Typography>
                }
                sx={{ alignItems: 'center', mb: 1 }}
            />
            {/*<ReCAPTCHA*/}
            {/*    sitekey="6LdeREApAAAAAJ5gdMN_hnQu4lh4r2WvL-L0old_"*/}
            {/*    onChange={onChange}*/}
            {/*/>*/}
            <Box pt={2} pb={2}>
                <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    variant="contained"
                    //disabled={!captchaOk}
                    disabled={!gdpr}
                    sx={{
                        textTransform: 'uppercase',
                        "&.Mui-disabled": {
                            background: "#eaeaea",
                            color: "#c0c0c0"
                        }
                    }}
                >
                    {t('inquiry_form.send')}
                </Button>
                <Typography
                    variant={"caption"}
                    color={theme.palette.primary.main}
                    style={animationStyle}
                >
                    {t('inquiry_form.success_message')}
                </Typography>
            </Box>
        </Box>
    );
}

export default InquiryForm;