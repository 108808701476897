import Slider from "../components/Slider";
import Brands from "../components/Brands";
import InfoContainer from "../components/InfoContainer";
import Gallery from "../components/Gallery";
import React from "react";
import RandomProductPage from "./RandomProductPage";
import {Helmet} from "react-helmet";

const HomePage = () => {
    return (
        <>
            <Helmet>
                {/* Základní metadata */}
                <title>Náhradní díly pro stavební, zemědělskou a manipulační techniku | Strojparts</title>
                <meta
                    name="description"
                    content="Strojparts nabízí široký sortiment náhradních dílů pro stavební, zemědělskou a manipulační techniku. Kvalitní díly pro značky jako Caterpillar, JCB, Volvo, Komatsu a další."
                />
                <meta
                    name="keywords"
                    content="náhradní díly, stavební technika, zemědělská technika, manipulační technika, Caterpillar, JCB, Volvo, Komatsu, STROJPARTS"
                />
                <meta name="author" content="STROJPARTS s.r.o." />

                {/* Open Graph (pro sociální sítě) */}
                <meta property="og:title" content="Náhradní díly pro stavební, zemědělskou a manipulační techniku | Strojparts" />
                <meta
                    property="og:description"
                    content="Strojparts nabízí široký sortiment náhradních dílů pro stavební, zemědělskou a manipulační techniku. Kvalitní díly pro značky jako Caterpillar, JCB, Volvo, Komatsu a další."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.strojparts.cz" />
                <meta property="og:image" content="https://www.strojparts.cz/assets/img/logo.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Náhradní díly pro stavební, zemědělskou a manipulační techniku | Strojparts" />
                <meta
                    name="twitter:description"
                    content="Strojparts nabízí široký sortiment náhradních dílů pro stavební, zemědělskou a manipulační techniku. Kvalitní díly pro značky jako Caterpillar, JCB, Volvo, Komatsu a další."
                />
                {/*<meta name="twitter:image" content="https://www.strojparts.cz/assets/img/logo.png" />*/}

                {/* Canonical URL */}
                <link rel="canonical" href="https://strojparts.cz" />

                {/* Favicon */}
                <link rel="icon" href="https://www.strojparts.cz/favicon.ico" />
            </Helmet>
            <Slider/>
            <Brands/>
            <InfoContainer/>
            {/*<Gallery />*/}
            <RandomProductPage />
        </>
    );
}

export default HomePage;