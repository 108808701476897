import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {InputAdornment, Link, MenuList, useTheme} from "@mui/material";

import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import ListItemLink from "./ListItemLink";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const pages = ['O Nás', 'Kontakt'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];




function NavBar() {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [search, setSearch] = useState<string>('');
    const [anchorElSearch, setAnchorElSearch] = useState<null | HTMLElement>(null);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const menuItem = [
        {
            name: t("nav.home"),
            path: ""
        },
        {
            name: "Servis převodovek a náprav",
            path: "servis-prevodovek-a-naprav"
        },
        {
            name: t("nav.product_catalog"),
            path: "catalog"
        }
    ]

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        console.log("close");
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // Funkce pro zpracování odeslání vyhledávání
    const handleSearchSubmit = () => {
        if (search) {
            setAnchorElSearch(null);
            navigate(`/catalog?search=${search}`);
            setSearch('');
        }
    };

    // Zpracování Enter klávesy
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleSearchClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSearch(event.currentTarget); // Otevření vyhledávacího menu
    };

    const handleSearchClose = () => {
        setAnchorElSearch(null); // Zavření vyhledávacího menu
    };

    return (
        <AppBar position="static" color={"secondary"} component="nav" sx={{ p: 1.5 }}>
            <Container maxWidth="xl">
                <Toolbar>
                    <Link
                        href="/"
                        underline="none"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        <img
                            className="nav-logo"
                            src={`/assets/img/logo.svg`}
                            alt="Strojparts s.r.o."
                        />
                    </Link>

                    {/*Mobilni cast*/}
                    {/*<AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}
                    <Link
                        href="/"
                        underline="none"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <img
                            className="nav-logo"
                            src={`/assets/img/logo.svg`}
                            alt="Strojparts s.r.o."
                        />
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>

                        <IconButton onClick={handleSearchClick} edge="end">
                            {anchorElSearch ? (
                                <CloseIcon color="primary" />
                            ) : (
                                <SearchIcon color="primary" />
                            )}
                        </IconButton>


                        <Menu
                            id="search-menu"
                            anchorEl={anchorElSearch}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElSearch)}
                            onClose={handleSearchClose}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                marginTop: 1
                            }}
                            marginThreshold={0}
                            slotProps={{
                                paper: {
                                    sx: {
                                        color: theme.palette.primary.main,
                                        width: '100%',
                                        maxWidth: '100%',
                                        left: '0px',
                                        right: '0px',
                                        backgroundColor: theme.palette.secondary.dark
                                    },
                                }
                            }}
                        >
                            <MenuItem disableRipple>
                                <TextField
                                    variant="standard"
                                    placeholder="Najít díl..."
                                    fullWidth
                                    value={search}
                                    onChange={(event) => setSearch(event.target.value)}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            handleSearchSubmit();
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            backgroundColor: '#595959',
                                            paddingLeft: '10px',
                                            color: 'primary.main', // Barva textu
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ paddingRight: '8px' }}>
                                                <IconButton onClick={handleSearchSubmit} edge="end">
                                                    <SearchIcon color="primary" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MenuItem>
                        </Menu>
                        {/*<LanguageSwitcher />*/}
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="primary"
                        >
                            {anchorElNav ? (
                                <CloseIcon color="primary" />
                            ) : (
                                <MenuIcon color="primary" />
                            )}
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                marginTop: 1
                            }}
                            marginThreshold={0}
                            slotProps={{
                                paper: {
                                    sx: {
                                        color: theme.palette.primary.main,
                                        width: '100%',
                                        maxWidth: '100%',
                                        left: '0px',
                                        right: '0px',
                                        backgroundColor: theme.palette.secondary.dark
                                    },
                                }
                            }}
                        >
                            {menuItem.map((item, index) => (
                                    <ListItemLink
                                        primary={item.name}
                                        to={'/' + item.path}
                                        onClick={handleCloseNavMenu}
                                        sxItem={{
                                            minWidth: 'auto',
                                            width: '100%',
                                            textAlign: 'center',
                                            flexGrow: 0
                                        }}
                                        sxText={{ color: theme.palette.primary.main }}
                                    />
                            ))}

                            <MenuItem  component={Link}  key="kontakt" onClick={handleCloseNavMenu} href="#contact">
                                <Typography
                                    sx={{
                                        minWidth: 'auto',
                                        width: '100%',
                                        textAlign: 'center',
                                        flexGrow: 0,
                                        fontWeight: 700
                                    }}>{t("contact")}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    {/*KONEC Mobilni cast*/}



                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>

                        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            {/* Vyhledávací pole uprostřed */}
                            <Box sx={{ flexGrow: 1,  display: { md: 'flex', sm: 'none', xs: 'none'}, justifyContent: 'end', alignItems: 'end', mx: 2 }}>
                                <TextField
                                    variant="standard"
                                    placeholder="Najít díl..."
                                    //fullWidth
                                    value={search}
                                    onChange={event => setSearch(event.target.value)}
                                    onKeyDown={handleKeyDown} // Pro stisk Enter
                                    InputProps={{
                                        sx: {
                                            backgroundColor: '#3a3a3a',
                                            paddingLeft: '10px', // Odsazení textu od levého okraje
                                            color: 'primary.main', // Barva textu nastavena na primární
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleSearchSubmit}
                                                    edge="end"
                                                    sx={{ paddingRight: '15px' }} // Odsazení ikony od pravého okraje
                                                >
                                                    <SearchIcon color="primary" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: 'primary.main', // Pokud máte label, nastavíte také barvu labelu
                                        },
                                    }}
                                />
                            </Box>
                            {menuItem.map((item, index) => (
                                <Grid item style={{ width: 'fixedWidth' }} key={index}>
                                    <ListItemLink
                                        primary={item.name}
                                        to={'/' + item.path}
                                        sxItem={{
                                            minWidth: 'auto',
                                            width: '100%',
                                            textAlign: 'center',
                                            flexGrow: 0,
                                        }}
                                        sxText={{ color: theme.palette.primary.main,
                                            transition: 'color 0.3s', // Add transition for color change
                                            '&:hover': {
                                                color: theme.palette.primary.dark, // Change text color on hover
                                            }
                                    }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        {/*<LanguageSwitcher />*/}
                        <Button component={Link} variant={"contained"} href="#contact" sx={{ marginLeft: '20px', textTransform: 'uppercase', fontSize: "1.25rem", paddingLeft: "48px", paddingRight: "48px", fontWeight: 700 }}>{t("contact")}</Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;