import React from 'react';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Slider from "react-slick";
import {Box, Button, CardHeader, Chip, Divider, Grid, Paper, Step, StepContent, StepLabel, Stepper, useTheme} from "@mui/material";
import {School, ThumbUp, Verified, SupportAgent, BuildOutlined, SettingsOutlined, DescriptionOutlined} from '@mui/icons-material';
import {Bolt, Build, Biotech} from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import EngineeringOutlined from '@mui/icons-material/EngineeringOutlined';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

const directory = '/assets/img/brands/servis/';
const assetUrls = ["dana.webp", "zf.webp", "carraro.webp", "comer.webp", "kessler.webp", "axletech.webp", "rockwell.webp", "oerlikon.webp"];


const ServisPage = () => {
    const theme = useTheme();
    const {t} = useTranslation();


    const headerBgStyle = {
        background: `linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), url('/assets/img/servis_prevodovek.webp')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'multiply',
        height: '85vh', // Výchozí hodnota
        [theme.breakpoints.down('lg')]: {
            height: '65vh',
        },
        [theme.breakpoints.down('md')]: {
            height: '50vh',
        },
        [theme.breakpoints.down('sm')]: {
            height: '30vh',
        },
    };

    return (
        <>
            <Box sx={headerBgStyle}>
                <Container
                    maxWidth="xl"
                    sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', height: '100%'}}
                >
                    <Box
                        component="img"
                        src="/assets/img/logo.svg"
                        alt="Logo"
                        sx={{
                            height: '96px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '32px',
                            [theme.breakpoints.up("md")]: {
                                height: '160px',
                                marginBottom: '80px',
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            [theme.breakpoints.down("sm")]: {
                                display: 'none',
                            },
                        }}
                    />
                    <Typography
                        variant={"h1"}
                        sx={{
                            fontSize: '2.25rem',
                            lineHeight: '40px',
                            color: '#fff',
                            [theme.breakpoints.down("md")]: {
                                fontSize: '1.25rem',
                                textAlign: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: '1.115rem',
                                textAlign: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                            },
                        }}
                    >
                        <Box>Nově provádíme servis převodovek a náprav pro stavební a zemědělské stroje.</Box>
                    </Typography>
                </Container>
            </Box>



            <Container maxWidth={false} sx={{
                    // paddingTop: '64px',
                    paddingBottom: '64px',
                    backgroundColor: '#f9f9f9',
                }}>
                {/*<Box*/}
                {/*    component="img"*/}
                {/*    src="/assets/img/servis_prevodovek_1.jpg"*/}
                {/*    alt="Servis převodovek"*/}
                {/*    sx={{*/}
                {/*        width: '100%', // Šířka obrázku přizpůsobená kontejneru*/}
                {/*        maxHeight: '800px', // Maximální výška obrázku*/}
                {/*        objectFit: 'contain', // Zajistí, že se obrázek přizpůsobí, aniž by byl oříznut*/}
                {/*        marginBottom: '32px', // Mezera pod obrázkem*/}
                {/*    }}*/}
                {/*/>*/}
                <Helmet>
                    {/* Základní metadata */}
                    <title>Servis převodovek a náprav | Strojparts</title>
                    <meta name="description"
                          content="Profesionální servis a opravy převodovek a náprav pro zemědělské a stavební stroje. Nabízíme kvalitní služby s využitím originálních dílů."/>
                    <meta name="keywords" content="servis převodovek, opravy náprav, zemědělské stroje, stavební stroje, náhradní díly, Strojparts"/>
                    <meta name="author" content="Strojparts"/>

                    {/* Open Graph (pro sociální sítě) */}
                    <meta property="og:title" content="Servis převodovek a náprav | Strojparts"/>
                    <meta property="og:description"
                          content="Profesionální servis a opravy převodovek a náprav pro zemědělské a stavební stroje. Nabízíme kvalitní služby s využitím originálních dílů."/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://www.strojparts.cz/servis-prevodovek"/>
                    {/*<meta property="og:image" content="https://www.strojparts.cz/assets/img/servis-preview.jpg" />*/}

                    {/* Twitter Card */}
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content="Servis převodovek a náprav | Strojparts"/>
                    <meta name="twitter:description"
                          content="Profesionální servis a opravy převodovek a náprav pro zemědělské a stavební stroje. Nabízíme kvalitní služby s využitím originálních dílů."/>
                    {/*<meta name="twitter:image" content="https://www.strojparts.cz/assets/img/servis-preview.jpg" />*/}

                    {/* Canonical URL */}
                    <link rel="canonical" href="https://strojparts.cz/servis-prevodovek"/>

                    {/* Favicon */}
                    <link rel="icon" href="/favicon.ico"/>
                    <script type="application/ld+json">
                        {JSON.stringify(
                            {
                                "@context": "https://schema.org",
                                "@type": "WebPage",
                                "name": "Servis převodovek a náprav | Strojparts",
                                "url": "https://strojparts.cz/servis-prevodovek",
                                "description": "Profesionální servis a opravy převodovek a náprav pro zemědělské a stavební stroje. Nabízíme kvalitní služby s využitím originálních dílů.",
                                "breadcrumb": {
                                    "@type": "BreadcrumbList",
                                    "itemListElement": [
                                        {
                                            "@type": "ListItem",
                                            "position": 1,
                                            "name": "Domů",
                                            "item": "https://strojparts.cz"
                                        },
                                        {
                                            "@type": "ListItem",
                                            "position": 2,
                                            "name": "Servis převodovek a náprav",
                                            "item": "https://strojparts.cz/servis-prevodovek"
                                        },
                                        {
                                            "@type": "ListItem",
                                            "position": 3,
                                            "name": "Katalog produktů",
                                            "item": "https://strojparts.cz/catalog"
                                        }
                                    ]
                                },
                                "publisher": {
                                    "@type": "Organization",
                                    "name": "Strojparts",
                                    "url": "https://strojparts.cz",
                                    "logo": {
                                        "@type": "ImageObject",
                                        "url": "https://strojparts.cz/favicon.ico"
                                    }
                                }
                            }
                        )}
                    </script>

                </Helmet>
                <Container maxWidth="xl">
                    {/*<Box*/}
                    {/*    component="img"*/}
                    {/*    src="/assets/img/servis_prevodovek.png"*/}
                    {/*    alt="Servis převodovek"*/}
                    {/*    sx={{*/}
                    {/*        width: '100%', // Šířka obrázku přizpůsobená kontejneru*/}
                    {/*        maxHeight: '800px', // Maximální výška obrázku*/}
                    {/*        objectFit: 'contain', // Zajistí, že se obrázek přizpůsobí, aniž by byl oříznut*/}
                    {/*        marginBottom: '48px', // Mezera pod obrázkem*/}
                    {/*    }}*/}
                    {/*/>*/}


                    {/* Z
                {/* Úvodní sekce */}
                    <Box pt={5} mb={0}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{textAlign: 'center', fontWeight: 'bold', marginBottom: 4}}>
                            Servis převodovek a náprav
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: 'justify',
                                lineHeight: 1.8,
                            }}
                        >
                            Naše firma <strong>Strojparts</strong> zajišťuje profesionální servis a opravy převodovek a náprav pro zemědělské a stavební
                            stroje.
                            Díky zkušenostem s předními světovými výrobci vám nabízíme kvalitu a spolehlivost, na kterou se můžete spolehnout.
                        </Typography>
                    </Box>

                    {/* Co nabízíme */}
                    <Box pt={4} mb={4}>
                        <Typography variant="h5" component="h2"
                                    sx={{fontWeight: 'bold', marginTop: 1, marginBottom: 2, textAlign: {xs: 'center', sm: 'left'}}}>
                            Co nabízíme?
                        </Typography>
                        <Grid container spacing={4}>
                            {/* Karta Opravy a servis */}
                            <Grid item xs={12} md={6}>
                                <Card
                                    elevation={3}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between', // Zajišťuje stejné rozložení obsahu
                                        padding: 3,
                                        backgroundColor: '#ffffff',
                                        borderRadius: 2,
                                        height: '100%', // Stejná výška karet
                                    }}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                        <Box
                                            sx={{
                                                width: '48px',
                                                height: '48px',
                                                borderRadius: '50%',
                                                backgroundColor: 'secondary.main',
                                                color: '#fff',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginRight: 2,
                                                    flexShrink: 0,
                                                }}
                                            >
                                                <BuildOutlined fontSize="medium" color={"primary"}/>
                                            </Box>
                                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                                Opravy a servis převodovek a náprav
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" sx={{marginBottom: 2}}>
                                            Specializujeme se na servis produktů těchto značek:
                                        </Typography>
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                                            {['DANA-SPICER', 'CARRARO', 'ZF-Group*'].map((brand, index) => (
                                                <Chip
                                                    key={index}
                                                    label={brand}
                                                    sx={{
                                                        backgroundColor: 'primary.main',
                                                        color: 'secondary.dark',
                                                        fontWeight: 'bold',
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        <Box
                                            sx={{
                                                marginTop: 'auto', // Zarovnání poznámky na spodek karty
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                pt={2}
                                                sx={{
                                                    color: 'text.secondary',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                * Pouze produkty z divize zemědělských a stavebních strojů.
                                            </Typography>
                                        </Box>
                                    </Card>
                                </Grid>

                                {/* Karta Náhradní díly */}
                                <Grid item xs={12} md={6}>
                                    <Card
                                        elevation={3}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between', // Zajišťuje stejné rozložení obsahu
                                            padding: 3,
                                            backgroundColor: '#ffffff',
                                            borderRadius: 2,
                                            height: '100%', // Stejná výška karet
                                        }}
                                    >
                                        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                            <Box
                                                sx={{
                                                    width: '48px',
                                                    height: '48px',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'secondary.main',
                                                    color: '#fff',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginRight: 2,
                                                    flexShrink: 0,
                                                }}
                                            >
                                                <SettingsOutlined fontSize="medium" color={"primary"}/>
                                            </Box>
                                            <Typography variant="h6" sx={{fontWeight: 'bold', color: 'secondary.main'}}>
                                                Náhradní díly
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" sx={{marginBottom: 2}}>
                                            Dodáváme širokou škálu náhradních dílů pro renomované značky:
                                        </Typography>
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                                            {['BREVINI', 'DANA-SPICER', 'CARRARO', 'ZF-Group*', 'COMER', 'KESSLER', 'AXLETECH', 'ROCKWELL', 'OERLIKON'].map(
                                                (brand, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={brand}
                                                        sx={{
                                                            backgroundColor: 'primary.main',
                                                            color: 'secondary.dark',
                                                            fontWeight: 'bold',
                                                        }}
                                                    />
                                                )
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                marginTop: 'auto', // Zarovnání poznámky na spodek karty
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                pt={2}
                                                sx={{
                                                    color: 'text.secondary',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                * Pouze produkty z divize zemědělských a stavebních strojů.
                                            </Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>


                        {/* Naše značky */}
                        <Box pt={8} mb={8}>
                            <Slider {...{
                                dots: false,
                                infinite: true,
                                slidesToShow: 7,
                                slidesToScroll: 1,
                                autoplay: true,
                                speed: 2000,
                                responsive: [
                                    {breakpoint: 1500, settings: {slidesToShow: 5}},
                                    {breakpoint: 1280, settings: {slidesToShow: 4}},
                                    {breakpoint: 900, settings: {slidesToShow: 3}},
                                    {breakpoint: 600, settings: {slidesToShow: 2}},
                                    {breakpoint: 480, settings: {slidesToShow: 1}}
                                ]
                            }}>
                                {assetUrls.map((url, index) => (
                                    <Box
                                        key={index}
                                        component="img"
                                        src={`${directory}${url}`}
                                        alt={`Logo ${url.replace('.webp', '')}`}
                                        sx={{
                                            height: '40px',
                                            objectFit: 'contain',
                                            marginRight: '16px',
                                        }}
                                    />
                                ))}
                            </Slider>
                        </Box>


                        {/* Postup spolupráce */}
                        <Box>
                            <Typography
                                variant="h5"
                                component="h2"
                                sx={{
                                    fontWeight: 'bold',
                                    marginTop: 6,
                                    marginBottom: 4,
                                    textAlign: {xs: 'center', sm: 'left'}
                                }}
                            >
                                Postup spolupráce
                            </Typography>

                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: 3,
                                    backgroundColor: '#ffffff',
                                    borderRadius: 2,
                                    boxShadow: 3,
                                }}
                                mb={4}
                            >
                                {/* Vertikální čára */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: {xs: '50%', sm: '28px'},
                                        top: '0',
                                        bottom: '0',
                                        width: '4px',
                                        transform: {xs: 'translateX(-50%)', sm: 'none'},
                                        backgroundColor: '#f0f0f0',
                                        zIndex: -1,
                                    }}
                                />
                                {[
                                    {
                                        icon: <BuildOutlined fontSize="medium" color="secondary"/>,
                                        label: "Demontáž převodovky nebo nápravy",
                                        description:
                                            "Zákazník zajistí demontáž zařízení ze stroje. Převodovku nebo nápravu umístí na paletu a my už zajistíme bezpečný převoz na renomovanou dílnu.",
                                    },
                                    {
                                        icon: <DescriptionOutlined fontSize="medium" color="secondary"/>,
                                        label: "Identifikace převodovky/nápravy",
                                        description:
                                            "Dodání identifikačních údajů ze štítku zařízení (typ, model, sériové číslo), což nám umožní přesnou diagnostiku a výběr dílů.",
                                    },
                                    {
                                        icon: <TroubleshootOutlinedIcon fontSize="medium" color="secondary"/>,
                                        label: "Diagnostika a návrh opravy",
                                        description: (
                                            <>
                                                <strong>Cena inspekce je 350€ bez DPH</strong> (v Kč dle aktuálního měsíčního kurzu). Pokud zákazník schválí
                                                opravu, náklady na inspekci nejsou účtovány.
                                            </>
                                        ),
                                    },
                                    {
                                        icon: <SettingsOutlined fontSize="medium" color="secondary"/>,
                                        label: "Realizace opravy",
                                        description:
                                            "Provádíme opravy s použitím originálních nebo ověřených náhradních dílů dle požadavků.",
                                    },
                                ].map((step, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: {xs: 'center', sm: 'flex-start'},
                                            marginBottom: 4,
                                            flexDirection: {xs: 'column', sm: 'row'},
                                            textAlign: {xs: 'center', sm: 'left'},
                                            '&:hover .icon': {
                                                backgroundColor: 'primary.main',
                                                color: 'primary.main',
                                            },
                                            '&:hover .icon > .content': {
                                                display: 'none', // Skryje číslo při hover
                                            },
                                            '&:hover .icon > .icon-hover': {
                                                display: 'flex', // Zobrazí ikonu při hover
                                            },
                                        }}
                                    >
                                        {/* Ikona kroku */}
                                        <Box
                                            className="icon"
                                            sx={{
                                                width: '56px',
                                                height: '56px',
                                                minWidth: '56px',
                                                borderRadius: '50%',
                                                backgroundColor: 'secondary.main',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'primary.main',
                                                fontSize: '1.5rem',
                                                fontWeight: 'bold',
                                                marginBottom: {xs: 2, sm: 0},
                                                marginRight: {xs: 0, sm: 3},
                                                zIndex: 1,
                                                position: 'relative',
                                                transition: 'all 0.3s ease',
                                            }}
                                        >
                                            {/* Číslo kroku */}
                                            <Box
                                                className="content"
                                                sx={{
                                                    display: 'flex', // Výchozí zobrazení čísla
                                                }}
                                            >
                                                {index + 1}
                                            </Box>
                                            {/* Ikona kroku */}
                                            <Box
                                                className="icon-hover"
                                                sx={{
                                                    display: 'none', // Skryté při výchozím stavu
                                                }}
                                            >
                                                {step.icon}
                                            </Box>
                                        </Box>
                                        {/* Text kroku */}
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    marginBottom: 1,
                                                    color: 'primary.main',
                                                }}
                                            >
                                                {step.label}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: 'text.secondary',
                                                    textAlign: {xs: 'center', sm: 'justify'},
                                                }}
                                            >
                                                {step.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>


                        {/* Dodávky technických převodovek */}
                        {/*<Box pt={2} sx={{marginBottom: 4}}>*/}
                        {/*    <Typography variant="h5" component="h2" sx={{fontWeight: 'bold', marginTop: 6, marginBottom: 2, textAlign: {xs: 'center', sm: 'left'}}}>*/}
                        {/*        Dodávky technických převodovek*/}
                        {/*    </Typography>*/}
                        {/*    <Typography*/}
                        {/*        variant="body1"*/}
                        {/*        sx={{*/}
                        {/*            marginBottom: 4,*/}
                        {/*            textAlign: 'justify',*/}
                        {/*            lineHeight: 1.8,*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        Dodáváme také technické převodovky a soukolí Brevini, které jsou ideální pro bioplynové stanice.*/}
                        {/*    </Typography>*/}

                        {/*    <Grid container spacing={4}>*/}
                        {/*        {[*/}
                        {/*            {*/}
                        {/*                icon: <EngineeringOutlined sx={{fontSize: '4rem'}}/>,*/}
                        {/*                title: "Vysoká odolnost a výkon",*/}
                        {/*                description: "Převodovky Brevin jsou navrženy tak, aby vydržely i ty nejnáročnější podmínky."*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                icon: <CategoryOutlined sx={{fontSize: '4rem'}}/>,*/}
                        {/*                title: "Modulární design",*/}
                        {/*                description: "Možnost přizpůsobení specifickým požadavkům."*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                icon: <EnergySavingsLeafOutlinedIcon sx={{fontSize: '4rem'}}/>,*/}
                        {/*                title: "Pohony pro bioplyn",*/}
                        {/*                description: "Pohon míchacích systémů pro rovnoměrnou distribuci tepla a kvalitní produkci bioplynu."*/}
                        {/*            },*/}
                        {/*        ].map((item, index) => (*/}
                        {/*            <Grid item xs={12} sm={6} md={4} key={index}>*/}
                        {/*                <Paper*/}
                        {/*                    elevation={3}*/}
                        {/*                    sx={{*/}
                        {/*                        height: '100%',*/}
                        {/*                        padding: 3,*/}
                        {/*                        textAlign: 'center',*/}
                        {/*                        display: 'flex',*/}
                        {/*                        flexDirection: 'column',*/}
                        {/*                        justifyContent: 'space-between',*/}
                        {/*                        alignItems: 'center',*/}
                        {/*                        backgroundColor: '#ffffff',*/}
                        {/*                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',*/}
                        {/*                        '&:hover': {*/}
                        {/*                            transform: 'scale(1.05)',*/}
                        {/*                            boxShadow: '0px 8px 16px rgba(0,0,0,0.2)',*/}
                        {/*                        },*/}
                        {/*                    }}*/}
                        {/*                >*/}
                        {/*                    <Typography*/}
                        {/*                        variant="h3"*/}
                        {/*                        component="div"*/}
                        {/*                        sx={{color: 'primary.main'}}*/}
                        {/*                    >*/}
                        {/*                        {item.icon}*/}
                        {/*                    </Typography>*/}
                        {/*                    <Typography*/}
                        {/*                        variant="h6"*/}
                        {/*                        component="h3"*/}
                        {/*                        sx={{*/}
                        {/*                            fontWeight: 'bold',*/}
                        {/*                            marginBottom: 1,*/}
                        {/*                            minHeight: '48px', // Nastavení stejné výšky pro nadpis*/}
                        {/*                            display: 'flex',*/}
                        {/*                            alignItems: 'center',*/}
                        {/*                            justifyContent: 'center',*/}
                        {/*                        }}*/}
                        {/*                    >*/}
                        {/*                        {item.title}*/}
                        {/*                    </Typography>*/}
                        {/*                    <Typography*/}
                        {/*                        variant="body2"*/}
                        {/*                        sx={{*/}
                        {/*                            color: 'text.secondary',*/}
                        {/*                            minHeight: '64px', // Nastavení stejné výšky pro popis*/}
                        {/*                            display: 'flex',*/}
                        {/*                            alignItems: 'center',*/}
                        {/*                            justifyContent: 'center',*/}
                        {/*                        }}*/}
                        {/*                    >*/}
                        {/*                        {item.description}*/}
                        {/*                    </Typography>*/}
                        {/*                </Paper>*/}
                        {/*            </Grid>*/}
                        {/*        ))}*/}
                        {/*    </Grid>*/}
                        {/*</Box>*/}


                        {/* Proč právě Strojparts */}
                        <Box pt={4} sx={{marginBottom: 4}}>
                            <Typography variant="h5" sx={{fontWeight: 'bold', marginBottom: 2, textAlign: {xs: 'center', sm: 'left'}}}>
                                Proč právě Strojparts?
                            </Typography>
                            <Grid container spacing={4}>
                                {[
                                    {title: "Odbornost", description: "Máme dlouholeté zkušenosti v oboru.", icon: <School fontSize="large" color="primary"/>},
                                    {
                                        title: "Férový přístup",
                                        description: "Platíte pouze za realizované práce.",
                                        icon: <ThumbUp fontSize="large" color="primary"/>
                                    },
                                    {
                                        title: "Spolehlivost",
                                        description: "Používáme kvalitní díly a ověřené postupy.",
                                        icon: <Verified fontSize="large" color="primary"/>
                                    },
                                    {
                                        title: "Zákaznická podpora",
                                        description: "Jsme tu pro vás od konzultace až po realizaci.",
                                        icon: <SupportAgent fontSize="large" color="primary"/>
                                    },
                                ].map((item, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={3}>
                                        <Box sx={{textAlign: 'center', padding: 2}}>
                                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1}}>
                                                {item.icon}
                                            </Box>
                                            <Typography variant="h6" sx={{marginBottom: 1}}>{item.title}</Typography>
                                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                {item.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>


                    </Container>
                </Container>
            </>

            );
            };

            export default ServisPage;
