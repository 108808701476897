import {Route, Routes} from "react-router-dom";
import LoginUser from "../components/auth/LoginUser";
import App from "../App";
import AdminPage from "../pages/admin/AdminPage";
import CategoryPage from "../pages/admin/CategoryPage";
import ProductPage from "../pages/admin/ProductPage";
import {Fragment} from "react";
import Dashboard from "../pages/admin/Dashboard";
import HomePage from "../pages/HomePage";
import ProductCatalogPage from "../pages/ProductCatalogPage";
import ProductDetail from "../components/Product/ProductDetail";
import ServisPage from "../pages/ServisPage";

const AppRouter = () => {
    return (
        <Routes>
            <Route key={"route-login"} path="/login" element={<LoginUser/>}/>
            <Route key="route-root" path="/" element={<App/>}>
                <Route index element={<HomePage />}/> {/* Indexní cesta pro obsah hlavní stránky */}
                <Route path="catalog" element={<ProductCatalogPage />}/>
                <Route path="servis-prevodovek-a-naprav" element={<ServisPage />}/>
                <Route path="product/:productId/:name?" element={<ProductDetail />} />
            </Route>
            <Route key="route-root-admin" path="/admin" element={<AdminPage />}>
                <Route index element={<Dashboard/>}/>
                <Route path="category" element={<CategoryPage/>}/>
                <Route path="products" element={<ProductPage/>}/>
            </Route>
        </Routes>
    );
}

export default AppRouter;